<template>
  <div class="top-user">
    <Row>
      <Col span="10" class="top">
        <div class="top-l">
          <div class="top-l-l" @click="$router.push(`/home/index/${exhibitionId}`)">
            <!-- <a href="javascript:;" 
              ><img src="@/assets/img/icon-zsx2.png"
            /></a> -->
            <span>用户中心</span>
          </div>
        </div>
      </Col>
      <Col span="14">
        <div class="inp-box" v-if="enabledSupplyDemand">
          <Button type="primary" ghost style="margin-left: 20px; border-radius: 0" @click="addNeeds()">免费发布需求</Button>
        </div>
      </Col>
    </Row>
    <needsModel ref="needsModel"></needsModel>
  </div>
</template>

<script>
import needsModel from "@components/needsModel";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "top-ex",
  components: {
    needsModel,
  },
  data() {
    return {
      search: "展商",
      type: "展商",
      arr: [
        {
          value: "展商",
          label: "展商",
        },
        {
          value: "展品",
          label: "展品",
        },
        {
          value: "活动",
          label: "活动",
        },
        {
          value: "供需",
          label: "供需",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUser",
      exhibitionId: "getExhibitionId",
      login: "getLogin",
      exhibitionConfig: "getExhibitionConfig",
    }),
    isRegOk() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    enabledSupplyDemand() {
      return this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.feature.enabledSupplyDemand;
    },
  },
  methods: {
    ...mapActions({
      unverifiedDialog: "unverifiedDialog",
    }),
    addNeeds() {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if(this.userInfo&&this.userInfo.inMember.isBlacklist){
        this.$Message.warning('您已被屏蔽');
        return 
      }
      if(!this.getRoleHandle('L1Demand-L2List-L3List')){
        return 
      }
      this.$refs.needsModel.open();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.top-user {
  width: 1200px;
  margin: 0 auto;
  padding: 25px 0;
  .top {
    &-msg {
      position: relative;
      padding: 0 16px 0 10px;
      border-left: 1px solid #e6e6e6;
      font-size: 12px;
      color: #333;
      h2 {
        font-size: 14px;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-bottom: 6px;
        span {
          @include font_color(#0190fe);
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
    &-l {
      &-l {
        display: flex;
        align-items: center;
        img {
          vertical-align: middle;
          margin-right: 10px;
        }
        span {
          font-size: 18px;
          color: #333;
          display: inline-block;
          // border-left: 1px solid #e8e8e8;
          // padding-left: 10px;
        }
      }
    }
    &-r {
    }
  }
  .inp {
    width: 420px;
    display: flex;
    border-left: solid 1px #e0e0e0;
    .line {
      height: 36px;
      // width: 1px;
      // background: #e0e0e0;
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        position: absolute;
        height: 1;
        content: "";
        display: inline-block;
        width: 358px;
        border-top: solid 1px #e0e0e0;
        z-index: 10;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        position: absolute;
        height: 1;
        content: "";
        display: inline-block;
        width: 358px;
        border-top: solid 1px #e0e0e0;
        z-index: 10;
      }
    }
    ::v-deep {
      .ivu-input,
      .ivu-select-selection {
        // height: 36px;
        border-color: transparent;
        box-shadow: 0 0 0 2px transparent;
      }
    }
  }
}
.inp-box {
  display: flex;
  justify-content: flex-end;
}
.search-info {
  margin: 15px 0 26px;
  font-size: 12px;
  color: #666;
  .item {
    display: inline-block;
    padding-right: 20px;
    cursor: pointer;
    &.active {
      @include font_color(#1890ff);
    }
  }
}
.histroy {
  width: 266px;
  height: 154px;
  background-color: #fff;
  border: solid 1px #e0e0e0;
}
</style>
