<template>
  <div class="top">
    <topInfo :isUser="showUser"></topInfo>
    <topEx v-if="!showUser"></topEx>
    <topLink v-if="!showUser"></topLink>
    <topUser v-if="showUser"></topUser>
  </div>
</template>

<script>
import topInfo from "@components/top/top-info.vue";
import topEx from "@components/top/top-ex.vue";
import topLink from "@components/top/top-link.vue";
import topUser from "@components/top/top-user.vue";
export default {
  name: "top",
  components: {
    topInfo,
    topEx,
    topLink,
    topUser
  },
  props:['showUser'],
  data(){
    return{
      // showUser:false,
    }
  },
  // watch:{
  //   '$route'(to,form){
  //     if(to.path.search('user') != -1) {
  //       this.showUser = true;
  //     }
  //   }
  // },
  // created() {
  //   if(location.href.search('user') != -1){
  //     this.showUser = true;
  //   }
  // },
};
</script>
 
<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.top {
  background: #fff;
}
</style>
