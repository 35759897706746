<template>
  <div :style="{ minHeight: bodyHeight + 'px' }" class="layout">
    <top :showUser="showUser"></top>
    <div
      class="content"
      :style="{
        minHeight: !showUser ? bodyHeight - 297 + 'px' : bodyHeight - 195 + 'px',
      }"
    >
      <router-view />
    </div>
    <copyright></copyright>
    <gSide v-if="!showUser"></gSide>
  </div>
</template>

<script>
import top from "@components/top/top.vue";
import copyright from "@components/copyright/copyright.vue";
import gSide from "@components/gSide/gSide.vue";

export default {
  name: "layout",
  components: {
    top,
    copyright,
    gSide,
  },
  data() {
    return {
      bodyHeight: "800px",
      showUser: false,
    };
  },
  watch: {
    $route(to, form) {
      if (to.path.search("user") != -1) {
        this.showUser = true;
      } else {
        this.showUser = false;
      }
    },
  },
  created() {
    this.bodyHeight = window.innerHeight ? window.innerHeight : "700";
    if (location.href.search("user") != -1) {
      this.showUser = true;
    } else {
      this.showUser = false;
    }
  }, 
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.layout {
  background: #f7f7f7;
  .content {
    min-height: 700px;
  }
}
</style>
