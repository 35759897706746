<template>
  <div class="top-ex">
    <Row>
      <Col class="top" span="14">
      <div class="top-l">
        <div class="top-l-l">
          <a @click="$router.push(`/home/index/${exhibitionId}`)" class="top_logo" href="javascript:;">
            <img :src="exhibitionInfo.logo" v-if="exhibitionInfo && exhibitionInfo.logo" />
            <img src="@/assets/img/empty.png" v-else />
          </a>
          <div class="top-msg">
            <h2>
              <span class="ex-name">{{ exhibitionInfo.name }}</span>
              <span class="ex-logo">
                官方在线
                <img src="@assets/img/vip.png" />
              </span>
            </h2>
            <div class="time_addr">
              <p>
                {{ exhibitionInfo.startData | moment("YYYY年MM月DD日 - ") }}
                {{ exhibitionInfo.endDate | moment("MM月DD日") }}<span>{{ exhibitionInfo.address }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      </Col>
      <Col span="10">
      <div class="info-box">
        <swiper :options="swiperOption" class="info-swiper">
          <swiper-slide v-if="pcShowExhibitionUserCount">已有 {{ numFormat(exhibitionInfo.userCount) }} 人访问 </swiper-slide>
          <swiper-slide v-if="pcShowExhibitionViewCount">累计访问 {{ numFormat(exhibitionInfo.viewCount) }} 人次</swiper-slide>
        </swiper>
      </div>
      <div class="inp-box">
        <div class="inp-wrap">
          <div class="inp">
            <div class="line"></div>
            <iSelect @on-change="selectHandle" style="width: 74px" v-model="type" class="select_search">
              <Option :key="'search-' + item.value" :value="item.value" v-for="item in arr">{{ item.label }}</Option>
            </iSelect>
            <Input @on-search="searchHandle" enter-button="搜索" search style="width: 260px" v-model="search" />
          </div>
          <!-- <div class="search-info">
              <div
                :class="{ active: item === search }"
                :key="'se-' + index"
                @click="search = item"
                class="item"
                v-for="(item, index) in history"
              >{{ item }}</div>
            </div> -->
        </div>
        <Button @click="addNeeds()" ghost style="margin-left: 14px; border-radius: 0" type="primary" v-if="enabledSupplyDemand">免费发布需求</Button>
      </div>
      </Col>
    </Row>
    <needsModel ref="needsModel"></needsModel>
  </div>
</template>
<script>
import moment from "moment";
import needsModel from "@components/needsModel";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "top-ex",
  components: {
    needsModel,
  },
  data () {
    return {
      history: [],
      searchType: "show.search.exhibitors",
      search: "",
      type: "2",
      arr: [
        {
          value: "1",
          label: "展商",
        },
        {
          value: "2",
          label: "展品",
        },
        // {
        //   value: "3",
        //   label: "活动",
        // },
        // {
        //   value: "4",
        //   label: "供需",
        // },
      ],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 4000,
        },
        direction: "vertical", //水平(horizontal)或垂直(vertical)
      },
    };
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      searchTxt: "getSearchTxt",
      Type: "getSearchType",
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      login: "getLogin",
      exhibitionConfig: "getExhibitionConfig",
    }),
    isRegOk () {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    enabledSupplyDemand () {
      return this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.feature.enabledSupplyDemand;
    },
    pcShowExhibitionViewCount () {
      return this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.display.pcShowExhibitionViewCount;
    },
    pcShowExhibitionUserCount () {
      return this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.display.pcShowExhibitionUserCount;
    },
  },
  watch: {
    $route (to, form) {
      let url = "";
      if (this.type === "1") {
        url = "/home/exhibitor";
      }
      if (this.type === "2") {
        url = "/home/product";
      }
      if (this.type === "3") {
        url = "/home/activity";
      }
      if (this.type === "4") {
        url = "/home/demand";
      }
      if (location.pathname.indexOf(url) === -1) {
        this.setSearchTxt("");
      }
    },
  },
  created () {
    this.init();
    this.search = "";
    this.setSearchTxt("");
    this.type = this.Type || "2";
  },
  methods: {
    ...mapMutations({
      setSearchTxt: "setSearchTxt",
      setSearchType: "setSearchType",
    }),
    ...mapActions({
      addSearch: "search/addSearch",
      getSearch: "search/getSearch",
      unverifiedDialog: "unverifiedDialog",
    }),
    async init () {
      this.getHistroy();
    },
    selectHandle (e) {
      if (e === "1") {
        this.searchType = "show.search.exhibitors";
      }
      if (e === "2") {
        this.searchType = "show.search.products";
      }
      if (e === "3") {
        this.searchType = "show.search.activities";
      }
      if (e === "4") {
        this.searchType = "show.search.supply-demand";
      }
      this.setSearchType(e);
      this.getHistroy();
      this.search = "";
      this.setSearchTxt("");
    },
    async getHistroy () {
      const bool = await this.getSearch({
        exhibitionId: this.exhibitionId,
        origin: this.searchType,
      });
      if (bool && bool.searchWordQuery) {
        this.history = bool.searchWordQuery.tops;
      }
    },
    addNeeds () {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.userInfo && this.userInfo.inMember.isBlacklist) {
        this.$Message.warning("您已被屏蔽");
        return;
      }
      if (!this.getRoleHandle("L1Demand-L2List-L3List")) {
        return;
      }
      this.$refs.needsModel.open();
    },
    async searchHandle () {
      this.setSearchTxt(this.search);

      if (!this.search) return;
      // show.search.exhibitors 展商搜索
      // show.search.products 展品搜索
      // show.search.activities 活动搜索
      // show.search.supply-demand 供需搜索
      let url = "";
      let bool = false; // 当前页面也是所选搜索的路由 
      if (this.type === "1") {
        url = "/home/exhibitor";
        this.searchType = "show.search.exhibitors";
        bool = window.location.pathname.includes(url);
      }
      if (this.type === "2") {
        url = "/home/product";
        this.searchType = "show.search.products";
        bool = window.location.pathname.includes(url);
      }
      if (this.type === "3") {
        url = "/home/activity";
        this.searchType = "show.search.activities";
        bool = window.location.pathname.includes(url);
      }
      if (this.type === "4") {
        url = "/home/demand";
        this.searchType = "show.search.supply-demand";
        bool = window.location.pathname.includes(url);
      }
      const opt = {
        itemId: this.exhibitionId,
        origin: this.searchType,
        word: this.search,
      };

      await this.addSearch(opt);
      this.getHistroy();
      if (bool) {
        this.$router.push(`${url}/${this.exhibitionId}`);
      } else {
        this.$store.commit("setCateArrVuex", []);
        this.$store.commit("setRegionArrVuex", []);
        this.$store.commit("setRegionArrNameVuex", []);
        this.$router.push(`${url}/${this.exhibitionId}`);
      }
    },
    numFormat (n) {
      if (!n) {
        return 0;
      }
      let num = n.toString();
      let decimals = "";
      // 判断是否有小数
      num.indexOf(".") > -1 ? (decimals = num.split(".")[1]) : decimals;
      let len = num.length;
      if (len <= 3) {
        return num;
      } else {
        let temp = "";
        let remainder = len % 3;
        decimals ? (temp = "." + decimals) : temp;
        if (remainder > 0) {
          // 不是3的整数倍
          return (
            num.slice(0, remainder) +
            "," +
            num
              .slice(remainder, len)
              .match(/\d{3}/g)
              .join(",") +
            temp
          );
        } else {
          // 是3的整数倍
          return (
            num
              .slice(0, len)
              .match(/\d{3}/g)
              .join(",") + temp
          );
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.top-ex {
  width: 1200px;
  margin: 0 auto;
  height: 100px;
  margin-top: 18px;
  margin-bottom: 18px;
  ::v-deep {
    .ivu-input-search:before {
      opacity: 0;
    }
  }
  .top {
    &-msg {
      position: relative;
      padding: 0 16px 0 10px;
      border-left: 1px solid #e6e6e6;
      font-size: 12px;
      color: #333;
      width: calc(100% - 140px);
      h2 {
        font-size: 14px;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-bottom: 6px;
        display: flex;
        align-items: flex-start;

        .ex-name {
          max-width: calc(100% - 100px);
          font-size: 18px;
        }
        .ex-logo {
          @include font_color(#0190fe);
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
          max-width: 100px;
          display: flex;
          align-items: center;
          font-size: 18px;
          img {
            width: 18px;
            height: 18px;
            position: relative;
            top: -1px;
            left: 2px;
          }
        }
      }
      .time_addr {
        font-size: 16px;
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          span {
            margin-left: 10px;
          }
        }
      }
    }
    &-l {
      &-l {
        display: flex;
        align-items: center;
        .top_logo {
          img {
            height: 100px;
            max-width: 150px;
          }
          margin-right: 10px;
        }
        img {
          vertical-align: middle;
          display: block;
        }
      }
    }
    &-r {
    }
  }
  .inp {
    width: 330px;
    display: flex;
    border-left: solid 1px #e0e0e0;
    &::v-deep .select_search {
      .ivu-select-selection {
        .ivu-select-selected-value {
          @include font_color(#1890ff);
        }
      }
    }
    .line {
      height: 36px;
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        position: absolute;
        height: 1;
        content: "";
        display: inline-block;
        width: 268px;
        border-top: solid 1px #e0e0e0;
        z-index: 10;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        position: absolute;
        height: 1;
        content: "";
        display: inline-block;
        width: 268px;
        border-top: solid 1px #e0e0e0;
        z-index: 10;
      }
    }
    ::v-deep {
      .ivu-input,
      .ivu-input:hover,
      .ivu-input:active,
      .ivu-select-selection {
        border-color: transparent !important;
        box-shadow: 0 0 0 2px transparent;
      }
    }
  }
}
.inp-box {
  display: flex;
  justify-content: flex-end;
}
.search-info {
  margin: 15px 0 0;
  font-size: 12px;
  color: #666;
  .item {
    display: inline-block;
    padding-right: 20px;
    cursor: pointer;
    &.active {
      @include font_color(#1890ff);
    }
  }
}
.histroy {
  width: 266px;
  height: 154px;
  background-color: #fff;
  border: solid 1px #e0e0e0;
}
.info-box {
  height: 36px;
  line-height: 36px;
  padding-right: 130px;
  @include font_color(#1890ff);
  font-size: 14px;
  text-align: right;
  overflow: hidden;
  .info-swiper {
    height: 100%;
  }
}
</style>
